import { FocusScope } from "@radix-ui/react-focus-scope";
import * as React from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useForm } from "~/config/react-hook-form";
import { Box } from "~/design-system/Box";
import { Button } from "~/design-system/Button";
import { CheckboxGroup, CheckboxItem } from "~/design-system/CheckboxGroup";
import { Form } from "~/design-system/Form";
import { Stack } from "~/design-system/Stack";
import { Text } from "~/design-system/Text";
import { TextField } from "~/design-system/TextField";
import { Toggle } from "~/design-system/Toggle";
import { Tones } from "~/design-system/tokens";
import type { CopyProjectInput, CreateProjectInput } from "~/schema.graphql";
import { ProjectsMultiSelectFieldInput } from "~/screens/App/components/ProjectsMultiSelectFieldInput/ProjectsMultiSelectFieldInput";
import { createComponentHook } from "~/types";
import { CreateDemoProjectDialog } from "../../../CreateDemoProjectDialog/CreateDemoProjectDialog";
import { validationSchema } from "./create-project.validation";
import {
  useCopyProjectMutation,
  useCreateProjectMutation,
} from "./CreateProjectForm.graphql";

type CreateProjectFormProps = {
  onSuccess?: (projectId: string) => void;
  projectTemplate?: boolean;
};

const useCreateProjectForm = createComponentHook(
  (props: CreateProjectFormProps) => {
    const { t } = useTranslation();
    const [, createProject] = useCreateProjectMutation();
    const [, copyProject] = useCopyProjectMutation();
    const [isSubmitting, setIsSumitting] = React.useState(false);
    const [demoProjectDialogOpen, setDemoProjectDialogOpen] =
      React.useState(false);
    const [useExistingProject, setUseExistingProject] = React.useState(false);
    const [useNonTemplateProject, setUseNonTemplateProject] =
      React.useState(false);
    const form = useForm(validationSchema);

    React.useEffect(() => {
      form.reset({
        options: useExistingProject
          ? {
              labels: false,
              members: false,
              publicDocuments: false,
              restrictedDocuments: false,
              tasks: false,
              tasksComments: false,
              teams: false,
            }
          : {},
        projectId: undefined,
      });
    }, [form, useExistingProject]);

    const handleCreateProject = async (input: CreateProjectInput) => {
      const { data } = await createProject(
        {
          input: {
            ...input,
            is_project_template: props.projectTemplate ?? false,
          },
        },
        { additionalTypenames: ["projects"] }
      );
      if (data?.createProject?.__typename === "CreateProjectSuccess") {
        toast.success(
          <span data-intercom-target="project-created">
            {t(
              "screens.Project.CreateProjectDrawer.components.CreateProjectForm.createProjectSuccessToast",
              "Your project has been created"
            )}
          </span>
        );
        form.reset();
        props.onSuccess?.(data.createProject.project_id);
      } else {
        toast.error(
          t(
            "screens.Project.CreateProjectDrawer.components.CreateProjectForm.createProjectErrorToast",
            "Something went wrong while creating your project"
          )
        );
      }
    };

    const handleCopyProject = async (input: CopyProjectInput) => {
      const { data } = await copyProject(
        {
          input,
        },
        { additionalTypenames: ["projects"] }
      );
      if (data?.copyProject?.__typename === "CopyProjectSuccess") {
        toast.success(
          <span data-intercom-target="project-copied">
            {t(
              "screens.Project.CreateProjectDrawer.components.CreateProjectForm.copyProjectSuccessToast",
              "Your project has been copied"
            )}
          </span>
        );
        form.reset();
        props.onSuccess?.(data.copyProject.project_id);
      } else {
        toast.error(
          t(
            "screens.Project.CreateProjectDrawer.components.CreateProjectForm.copyProjectErrorToast",
            "Something went wrong while copying your project"
          )
        );
      }
    };

    const handleSubmit = form.handleSubmit(async (input) => {
      setIsSumitting(true);
      if (useExistingProject) {
        if (input.projectId) {
          await handleCopyProject({ ...input, projectId: input.projectId });
        } else {
          setIsSumitting(false);
          form.setError("projectId", {
            message: t(
              "screens.Project.CreateProjectDrawer.components.CreateProjectForm.shouldSelectProject",
              "You should select a project"
            ),
          });
        }
      } else {
        await handleCreateProject({ name: input.name });
      }
    });

    const membersOption = form.watch("options.members");

    return {
      actions: {
        handleSubmit,
        registerInput: form.register,
        setDemoProjectDialogOpen,
        setUseExistingProject,
        setUseNonTemplateProject,
        setValue: form.setValue,
      },
      state: {
        control: form.control,
        demoProjectDialogOpen,
        errors: form.formState.errors,
        isSubmitting,
        membersOption,
        useExistingProject,
        useNonTemplateProject,
      },
      t,
    };
  }
);

function CreateProjectForm(props: CreateProjectFormProps) {
  const { actions, state, t } = useCreateProjectForm(props);

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="full"
      justifyContent="spaceBetween"
    >
      <FocusScope asChild loop trapped>
        <Form onSubmit={actions.handleSubmit}>
          <Stack space="medium">
            <Stack space="gutter">
              <TextField
                {...actions.registerInput("name")}
                label={t(
                  "screens.Project.CreateProjectDrawer.components.CreateProjectForm.nameLabel",
                  "Project Name"
                )}
                tone={Tones.neutral}
                error={state.errors?.name?.message}
                required
                autoFocus
                dataIntercomTarget="project-create-name"
              />
              {props.projectTemplate ?? (
                <Toggle
                  on={state.useExistingProject}
                  onChange={actions.setUseExistingProject}
                  label={t(
                    "screens.Project.CreateProjectDrawer.components.CreateProjectForm.useExistingProjectLabel",
                    "Copy from project template"
                  )}
                />
              )}
              {state.useExistingProject ? (
                <Toggle
                  on={state.useNonTemplateProject}
                  onChange={actions.setUseNonTemplateProject}
                  label={t(
                    "screens.Project.CreateProjectDrawer.components.CreateProjectForm.useNonTemplate",
                    "Copy from other projects"
                  )}
                />
              ) : null}
              {state.useExistingProject && (
                <Stack space="small">
                  <ProjectsMultiSelectFieldInput
                    {...actions.registerInput("projectId")}
                    control={state.control}
                    error={state.errors.projectId?.message}
                    label={t(
                      "screens.Project.CreateProjectDrawer.components.CreateProjectForm.templateProjectSelectLabel",
                      "Select the project template to copy"
                    )}
                    required
                    onlyTemplates={!state.useNonTemplateProject}
                    multiple={false}
                    dataIntercomTarget="project-create-copy-projectId"
                  />
                  <Button
                    link
                    dataIntercomTarget="open-demo-project-dialog"
                    size="xsmall"
                    type="button"
                    css={{
                      paddingLeft: "1px",
                      paddingTop: "0px",
                    }}
                    onClick={() =>
                      (window.location.href =
                        "https://help.clovis.app/fr/collections/4059683-administrez-vos-projets")
                    }
                  >
                    {t(
                      "screens.Project.CreateProjectDrawer.components.CreateProjectForm.howToMakeProjectTemplate",
                      "How to make a project as project template? "
                    )}
                  </Button>
                  <CheckboxGroup checkboxGroupName="template_items">
                    <CheckboxItem
                      {...actions.registerInput("options.labels")}
                      onChange={(checked) => {
                        actions.setValue("options.labels", !!checked);
                      }}
                      checkboxGroupName="template_items"
                      value="tags"
                      label={t(
                        "screens.Project.CreateProjectDrawer.components.CreateProjectForm.includeProjectTagsLabel",
                        "Copy tags"
                      )}
                      dataIntercomTarget="project-create-copy-option-labels"
                    />
                    <CheckboxItem
                      {...actions.registerInput("options.teams")}
                      onChange={(checked) => {
                        actions.setValue("options.teams", !!checked);
                      }}
                      checkboxGroupName="template_items"
                      value="roles"
                      label={t(
                        "screens.Project.CreateProjectDrawer.components.CreateProjectForm.includeProjectTeamsLabel",
                        "Copy teams"
                      )}
                      dataIntercomTarget="project-create-copy-option-teams"
                    />
                    <CheckboxItem
                      {...actions.registerInput("options.tasks")}
                      onChange={(checked) => {
                        actions.setValue("options.tasks", !!checked);
                      }}
                      checkboxGroupName="template_items"
                      value="tasks"
                      label={t(
                        "screens.Project.CreateProjectDrawer.components.CreateProjectForm.includeProjecTasksLabel",
                        "Copy tasks"
                      )}
                      dataIntercomTarget="project-create-copy-option-tasks"
                    />
                    <CheckboxItem
                      {...actions.registerInput("options.members")}
                      onChange={(checked) => {
                        actions.setValue("options.members", !!checked);
                      }}
                      checkboxGroupName="template_items"
                      value="members"
                      label={t(
                        "screens.Project.CreateProjectDrawer.components.CreateProjectForm.includeProjectMembersLabel",
                        "Copy members"
                      )}
                      dataIntercomTarget="project-create-copy-option-members"
                    />
                    <CheckboxItem
                      {...actions.registerInput("options.publicDocuments")}
                      onChange={(checked) => {
                        actions.setValue("options.publicDocuments", !!checked);
                      }}
                      checkboxGroupName="template_items"
                      value="public_folders"
                      label={t(
                        "screens.Project.CreateProjectDrawer.components.CreateProjectForm.includeProjectPublicFoldersLabel",
                        "Copy public folders"
                      )}
                      dataIntercomTarget="project-create-copy-option-publicDocuments"
                    />
                    <CheckboxItem
                      {...actions.registerInput("options.restrictedDocuments")}
                      onChange={(checked) => {
                        actions.setValue(
                          "options.restrictedDocuments",
                          !!checked
                        );
                      }}
                      checkboxGroupName="template_items"
                      value="restrictions"
                      label={t(
                        "screens.Project.CreateProjectDrawer.components.CreateProjectForm.includeProjectRestrictedFoldersLabel",
                        "Copy restricted folders"
                      )}
                      dataIntercomTarget="project-create-copy-option-restrictedDocuments"
                      disabled={!state.membersOption}
                    />
                  </CheckboxGroup>
                </Stack>
              )}
            </Stack>
            <Stack>
              <Button
                dataIntercomTarget="create-project"
                size="large"
                tone="brandAccent"
                width="full"
                loading={state.isSubmitting}
              >
                {t(
                  "screens.Project.CreateProjectDrawer.components.CreateProjectForm.submitButton",
                  "Create New Project"
                )}
              </Button>
            </Stack>
          </Stack>
        </Form>
      </FocusScope>
      {props.projectTemplate ?? (
        <Button
          dataIntercomTarget="open-demo-project-dialog"
          tone="brandAccent"
          width="full"
          variant="text"
          type="button"
          onClick={() => actions.setDemoProjectDialogOpen(true)}
        >
          <Text tone="brandAccent" size="small">
            {t(
              "screens.Project.CreateProjectDrawer.components.CreateProjectForm.demoProjectButton",
              "Or create a demo project"
            )}
          </Text>
        </Button>
      )}
      <CreateDemoProjectDialog
        open={state.demoProjectDialogOpen}
        setOpen={actions.setDemoProjectDialogOpen}
        onSuccess={props.onSuccess}
      />
    </Box>
  );
}

export type { CreateProjectFormProps };
export { CreateProjectForm };
